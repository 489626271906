<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Action Parties</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link
                to="/action-parties/create"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="Add New Category"
                  @click="navigate"
                >
                  <i class="fas fa-plus-circle pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <template>
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <b-form-input
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Search By Action Parties"
                    v-model="search"
                  ></b-form-input>
                </div>
              </div>
              <hr />
              <v-data-table
                :headers="headers"
                :items="actionParties"
                :items-per-page="10"
                class="elevation-1"
                :search="search"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :x-small="true" :color="getColor(item.status)" dark>{{
                    item.status
                  }}</v-chip>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <div class="w-100 text-right">
                    <span
                      @click="viewDetail(item)"
                      v-b-tooltip.hover.top="'Details'"
                      class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Layout/Layout-horizontal.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="editItem(item.id)"
                      v-b-tooltip.hover.top="'Edit'"
                      class="btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-warning">
                        <inline-svg
                          src="media/svg/icons/Communication/Write.svg"
                        ></inline-svg>
                      </span>
                    </span>
                    <span
                      @click="deleteItem(item.id)"
                      v-b-tooltip.hover.top="'Delete'"
                      class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                      </span>
                    </span>
                  </div>
                </template>
              </v-data-table>
              <b-modal
                ref="item-detail"
                hide-footer
                title="Action Party Detail"
              >
                <div class="d-block">
                  <div class="row">
                    <div class="col-md-3">Name:</div>
                    <div class="col-md-9 mb-3">
                      <b>{{ name }}</b>
                    </div>
                    <div class="col-md-3">Description:</div>
                    <div class="col-md-9">
                      <b>{{ description }}</b>
                    </div>

                    <div class="col-md-12 d-flex justify-content-end">
                      <b-button
                        class="btn btn-light-danger font-weight-bold mt-3"
                        @click="hideDetail"
                        >Close</b-button
                      >
                    </div>
                  </div>
                </div>
              </b-modal>
            </template>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PilotCard from "@/view/content/Card.vue";
import {
  GET_ACTION_PARTIES,
  DELETE_ACTION_PARTY
} from "@/core/services/store/actionparty.module";
//import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      search: "",
      name: "",
      description: "",
      headers: [
        {
          text: "Action party",
          align: "start",
          value: "name"
        },
        { text: "Description", value: "description" },
        { text: "Actions", align: "end", sortable: false, value: "action" }
      ]
    };
  },
  components: {
    PilotCard
    //Multiselect
  },
  methods: {
    getColor(status) {
      if (status == "open") return "bg-danger";
      else if (status == "pending") return "bg-warning";
      else return "bg-success";
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this action party",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$store
            .dispatch(DELETE_ACTION_PARTY, id)
            .then(response => {
              this.$store.dispatch(GET_ACTION_PARTIES);
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Action Party Deleted",
            "Action Party was successfully deleted"
          );
        }
      });
    },
    viewDetail(actionParty) {
      this.name = actionParty.name;
      this.description = actionParty.description;
      this.$refs["item-detail"].show();
    },
    hideDetail() {
      this.name = "";
      this.description = "";
      this.$refs["item-detail"].hide();
    },
    editItem(id) {
      this.$router.push(`/action-parties/edit/${id}`);
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    },

    actionParties() {
      return this.$store.state.actionParty.actionParties;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Action Parties" }]);
    this.$store.dispatch(GET_ACTION_PARTIES);
  }
};
</script>
